var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"trending-news-area"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{class:[
            'section-title',
            _vm.darkClass && 'title' in _vm.darkClass ? _vm.darkClass.title : '' ]},[_c('h3',{staticClass:"title"},[_vm._v("Trending News")])]),_c('div',{staticClass:"trending-news-slider position-relative"},[_c('span',{staticClass:"prev slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.trandingPrev}},[_c('i',{staticClass:"fal fa-angle-left"})]),_c('slider',{ref:"trendingSlider",attrs:{"settings":_vm.trendingSettings}},[_vm._l((_vm.TrendingPosts),function(data,index){return [_c('div',{key:index,staticClass:"px-2"},[_c('divide-card',{class:[
                    _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '' ],attrs:{"stype":"col","datas":data}})],1)]})],2),_c('span',{staticClass:"next slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.trandingNext}},[_c('i',{staticClass:"fal fa-angle-right"})])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{class:[
                'trending-news-post-items',
                _vm.darkClass && 'news_section' in _vm.darkClass
                  ? _vm.darkClass.news_section
                  : '' ]},[_vm._l((_vm.smallPostGallery.slice(0, 3)),function(small,index){return [_c('row-card',{key:index,class:[
                    _vm.darkClass && 'news_item' in _vm.darkClass
                      ? _vm.darkClass.news_item
                      : '' ],attrs:{"trending":true,"datas":small}})]})],2)]),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{class:[
                'trending-news-post-items',
                _vm.darkClass && 'news_section' in _vm.darkClass
                  ? _vm.darkClass.news_section
                  : '' ]},[_vm._l((_vm.smallPostGallery.slice(3, 6)),function(small,index){return [_c('row-card',{key:index,class:[
                    _vm.darkClass && 'news_item' in _vm.darkClass
                      ? _vm.darkClass.news_item
                      : '' ],attrs:{"trending":true,"datas":small}})]})],2)])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"trending-right-sidebar"},[_c('FollowCount',{attrs:{"dark":_vm.dark}}),_c('div',{staticClass:"trending-most-view mt-25"},[_c('div',{class:['section-title', _vm.dark ? 'section-title-2' : '']},[_c('h3',{staticClass:"title"},[_vm._v("Most View")])])]),_c('div',{staticClass:"trending-sidebar-slider position-relative"},[_c('span',{staticClass:"prev slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.tssPrev}},[_c('i',{staticClass:"fal fa-angle-left"})]),_c('slider',{ref:"trendingSidebarSlide",attrs:{"settings":_vm.trendingSidebarSlide}},[_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.smallPostGallery.slice(0, 6)),function(small,index){return [_c('row-card',{key:index,class:[
                      _vm.darkClass && 'news_item' in _vm.darkClass
                        ? _vm.darkClass.news_item
                        : '' ],attrs:{"counting":true,"count":index + 1,"datas":small}})]})],2),_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.smallPostGallery.slice(0, 6)),function(small,index){return [_c('row-card',{key:index,class:[
                      _vm.darkClass && 'news_item' in _vm.darkClass
                        ? _vm.darkClass.news_item
                        : '' ],attrs:{"counting":true,"count":index + 1,"datas":small}})]})],2)]),_c('span',{staticClass:"next slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.tssNext}},[_c('i',{staticClass:"fal fa-angle-right"})])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }