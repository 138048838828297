var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('video-pop-up'),_c('drawer',{attrs:{"sidebar":_vm.sidebar},on:{"toggleSidebar":_vm.toggleSidebar}}),_c('Header',{staticClass:"header-dark",attrs:{"image":"logo-2.png","darkClass":{ menuItem: 'newspark-dark-menu-items' }},on:{"toggleSidebar":_vm.toggleSidebar}}),_c('post-gallery',{staticClass:"pt-50",attrs:{"darkClass":{
      section: 'post_gallery_sidebar_dark',
      item: 'gallery_item_dark',
    }}}),_c('features',{attrs:{"dark":true}}),_c('trending-news',{attrs:{"dark":true,"darkClass":{
      title: 'section-title-2',
      item: 'trending-news-item-dark',
      news_section: 'trending-news-post-items-dark',
      news_item: 'gallery_item_dark',
    }}}),_c('single-play-post',{staticClass:"single-play-post-dark-area"}),_c('video-news',{staticClass:"video-news-dark",attrs:{"dark":true,"darkClass":{
      section: 'video-news-box-dark',
      section_2: 'video-news-post-item-dark',
      news_item: 'gallery_item_dark',
    }}}),_c('home-one-post-area',{attrs:{"dark":true}}),_c('footer-dark'),_c('back-to-top')],1)}
var staticRenderFns = []

export { render, staticRenderFns }